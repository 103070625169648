import { 
  SET_AUTH_USER,
  UNSET_AUTH_USER,
  REQUEST_USER_SUBSCRIPTION_SUCCESS,
  SET_SELECTED_GAME_SUCCESS,
} from './types';
import { USER_GROUP_TESTER, USER_GROUP_TOURNAMENT_ORGANIZER } from '../../util/Constants';
import Cookies from 'js-cookie';
import { GAME_NAMES, PUBG_GAME } from '../../util/GamesConstants';

const INITIAL_STATE = {
  selectedGame: getSelectedGame(),
  loginDetermined: false,
  username: null,
  sub: null,
  userGroups: [],
  tester: false,
  tournamentOrganizer: false,
  premium: false,
  trial: false,
  premiumValidFrom: null,
  premiumValidTo: null,
  country: null,
  countryCode: null,
  continentCode: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On set authenticated user
    case SET_AUTH_USER:
      return {
        ...state,
        loginDetermined: true,
        username: action.payload.username,
        sub: action.payload.sub,
        userGroups: action.payload.userGroups,
        tester: action.payload.userGroups && action.payload.userGroups.includes(USER_GROUP_TESTER),
        tournamentOrganizer: action.payload.userGroups && action.payload.userGroups.includes(USER_GROUP_TOURNAMENT_ORGANIZER),
      };

    // On remove authenticated user
    case UNSET_AUTH_USER:
      return {
        selectedGame: state.selectedGame,
        loginDetermined: true,
        username: null,
        sub: null,
        userGroups: [],
        tester: false,
        tournamentOrganizer: false,
        premium: false,
        trial: false,
        premiumValidFrom: null,
        premiumValidTo: null,
        country: null,
        countryCode: null,
        continentCode: null,
      };

    // Hide pricing modal
    case REQUEST_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        premium: true,
        trial: action.payload.trial,
        premiumValidFrom: action.payload.validFrom,
        premiumValidTo: action.payload.validTo,
      }

    // On set selected game
    case SET_SELECTED_GAME_SUCCESS:
      return {
        ...state,
        selectedGame: action.payload,
      }
      
    default:
      return state;
  }
}

export default reducer;

/**
 * 
 * @returns Returns selected game
 */
function getSelectedGame() {
  const gameFromUrl = window.location.href.split('/').find(s => GAME_NAMES[s]);
  if (GAME_NAMES[gameFromUrl]) {
    return gameFromUrl;
  }
  return Cookies.get('selected-game') || PUBG_GAME;
}